import { useEffect, useState } from 'react';
import { ChevronDown, ChevronLeft } from '../../../components/icons';
import { useGetSettings } from '../../../services/settings';
import { capitalized } from '../../../utils/capitalize';
import Bank from './product-settings/bank/bank';
import Cards from './product-settings/cards';
import GiftCards from './product-settings/gift-cards';
import Cable from './product-settings/pay-bills/cable';
import CheapData from './product-settings/pay-bills/cheapData';
import Electricity from './product-settings/pay-bills/electricity';
import MobileData from './product-settings/pay-bills/mobileData';
import Payoneer from './product-settings/payoneer';
import CashBack from './rewards/cash-back';
import ReassignAccount from './site-settings/reassign-account';
import TransactionLimits from './site-settings/transaction-limits';
import VersionControl from './site-settings/version-control';
import Airtime from './product-settings/pay-bills/airtime';
import Referral from './rewards/referral';
import BusinessReg from './site-settings/business-registration';
import ProviderRefund from './site-settings/provider-refund';
import Repush from './site-settings/repush';
import Providers from './site-settings/providers';
import AirtimeToCash from './product-settings/airtime-to-cash';
import UpdateAccount from './site-settings/update-account';
import General from './site-settings/general';
import SignupBonus from './rewards/signup-bonus';
import MarketCampaigns from './market-campaigns/createCampaigns';
import AllCampaigns from './market-campaigns/allCampaigns';
import PromoData from './product-settings/promo-data';

interface IAccordionProps {
  title: string;
  content: IContent[];
}

interface IContent {
  contentTitle: string;
}

const Settings = () => {
  const { data, refetch } = useGetSettings();
  const [menu, setMenu] = useState<boolean>(false);
  const settingsData = data?.data;
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [activeItem, setActiveItem] = useState<IAccordionProps>(
    {} as IAccordionProps
  );
  const [activeContent, setActiveContent] = useState<string>('');

  const handleClick = (index: number) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  useEffect(() => {
    setActiveIndex(0);
    setActiveItem(AccordionProps[0]);
    setActiveContent(AccordionProps[0]?.content[0]?.contentTitle);
  }, []);

  return (
    <div className="lg:flex h-screen block relative lg:static">
      <span
        onClick={() => setMenu(true)}
        className="flex lg:hidden justify-end mx-4"
      >
        <ChevronLeft
          className={`${
            menu ? 'hidden' : 'block lg:hidden '
          } lg:hidden fill-white h-6 w-6 mt-4`}
        />
      </span>
      <div
        className={`bg-black overflow-y-auto modalScrollBar pt-10 lg:w-[17rem] w-full ${
          menu ? 'block' : 'hidden lg:block'
        } h-screen absolute z-[4] lg:static`}
      >
        {AccordionProps.map((item: IAccordionProps, index) => (
          <div key={index}>
            <div
              className="flex justify-between items-center my-4 px-4 cursor-pointer"
              onClick={() => {
                handleClick(index);
              }}
            >
              <h5 className="text-grayText text-xs font-light">
                {item.title.toLocaleUpperCase()}
              </h5>
              <ChevronDown className="w-2 h-2" />
            </div>

            <div className="mb-2">
              {index === activeIndex &&
                item.content.map((content: IContent, index) => (
                  <div
                    key={index}
                    className={`flex flex-col mx-4 py-3 cursor-pointer ${
                      activeContent === content.contentTitle
                        ? 'bg-dark rounded-md'
                        : ''
                    }`}
                    onClick={() => {
                      setActiveContent(content.contentTitle);
                      setActiveItem(item);
                      menu && setMenu(false);
                    }}
                  >
                    <h5 className="font-medium px-2 text-sm">
                      {capitalized(content.contentTitle)}
                    </h5>
                  </div>
                ))}
            </div>
            <hr className="border-grey/30" />
          </div>
        ))}
      </div>
      <div className="bg-bColor w-full absolute z-[2] lg:static h-screen overflow-y-auto modalScrollBar">
        <div className="md:p-10 p-5">
          <h4 className="font-medium text-lg">
            {capitalized(activeItem.title)}
          </h4>
          <div className="pt-5">
            {activeContent === 'airtime' && (
              <Airtime settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'cheap data' && (
              <CheapData settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'electricity' && (
              <Electricity settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'cable' && (
              <Cable settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'mobile data' && (
              <MobileData settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'banks' && (
              <Bank settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'gift cards' && (
              <GiftCards settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'cards' && (
              <Cards settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'cash back' && (
              <CashBack settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'version control' && (
              <VersionControl settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'reassign account' && (
              <ReassignAccount settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'payoneer' && (
              <Payoneer settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'transaction limit' && (
              <TransactionLimits
                settingsData={settingsData}
                refetch={refetch}
              />
            )}
            {activeContent === 'referral' && (
              <Referral settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'business registration' && (
              <BusinessReg settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'provider refund' && <ProviderRefund />}
            {activeContent === 'repush' && <Repush />}
            {activeContent === 'cheapdata providers' && <Providers />}
            {activeContent === 'airtime to cash' && (
              <AirtimeToCash settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'update account' && <UpdateAccount />}
            {activeContent === 'general' && (
              <General settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'signup bonus' && (
              <SignupBonus settingsData={settingsData} refetch={refetch} />
            )}
            {activeContent === 'create campaign' && <MarketCampaigns />}
            {activeContent === 'view campaign' && <AllCampaigns />}
            {activeContent === 'promo data' && (
              <PromoData settingsData={settingsData} refetch={refetch} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;

const AccordionProps: IAccordionProps[] = [
  {
    title: 'site settings',
    content: [
      { contentTitle: 'general' },
      { contentTitle: 'version control' },
      { contentTitle: 'reassign account' },
      { contentTitle: 'update account' },
      { contentTitle: 'transaction limit' },
      { contentTitle: 'business registration' },
      { contentTitle: 'cheapdata providers' },
      { contentTitle: 'provider refund' },
      { contentTitle: 'repush' },
    ],
  },
  {
    title: 'product settings',
    content: [
      { contentTitle: 'gift cards' },
      { contentTitle: 'cards' },
      { contentTitle: 'airtime' },
      { contentTitle: 'cable' },
      { contentTitle: 'cheap data' },
      { contentTitle: 'electricity' },
      { contentTitle: 'mobile data' },
      { contentTitle: 'promo data' },
      { contentTitle: 'airtime to cash' },
      { contentTitle: 'banks' },
      { contentTitle: 'payoneer' },
    ],
  },
  {
    title: 'rewards',
    content: [
      { contentTitle: 'cash back' },
      { contentTitle: 'referral' },
      { contentTitle: 'signup bonus' },
    ],
  },
  {
    title: 'market campaigns',
    content: [
      { contentTitle: 'create campaign' },
      { contentTitle: 'view campaign' },
    ],
  },
];
